// Styles
import { useCallback, useEffect } from "react";
import styles from "./index.module.css";
import { useAuth0 } from "@auth0/auth0-react";
import { Grid, Popover, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import getUserFromToken from "../../../utils/tokenHelper";

import {
  faUserShield,
  faSignOut,
  faGear,
  faUser,
  faLanguage,
  faCircleQuestion,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

const UserMenu = ({
  canReachPages,
  userMenu,
  userMenuClose,
  handleLeftClick, // to admin or to tech
  handleCenterClick, // to change language or to clinic settings
  handleRightClick, // signout
  isAdmin,
  setIsAdmin,
  isAdminPage = false,
}) => {
  // internationalization
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  // routing
  // const navigate = useNavigate();

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (!setIsAdmin) return;
    const checkAdmin = async () => {
      const token = await getAccessTokenSilently();
      const { isAdmin } = getUserFromToken(token);

      setIsAdmin(isAdmin);
    };
    checkAdmin();
  }, [getAccessTokenSilently, setIsAdmin]);

  const customStyle = {
    width:
      window.innerWidth > 600
        ? i18n.language === "fr"
          ? "280px"
          : "200px"
        : "70%",
    top: window.innerWidth > 750 ? "30px" : "85px",
  };

  const handleClick = useCallback(
    () => navigate("/operating-manual"),
    [navigate]
  );

  /* menu pop when open */
  return (
    <Popover
      sx={{ mt: 0 }}
      open={Boolean(userMenu)}
      anchorEl={userMenu}
      onClose={userMenuClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      PaperProps={{
        style: customStyle,
      }}
      classes={{
        paper: "py-4 px-2",
      }}
    >
      {canReachPages && isAdmin && (
        <button className={styles.menu_item} onClick={handleLeftClick}>
          <Grid container>
            <Grid item xs={2} display={"flex"} alignItems={"center"}>
              {isAdminPage ? (
                <FontAwesomeIcon icon={faUser} size="1x" />
              ) : (
                <FontAwesomeIcon icon={faUserShield} size="1x" />
              )}
            </Grid>
            <Grid item xs={10}>
              <Typography>
                {isAdminPage ? t("word_back_to_tech") : t("word_admin")}
              </Typography>
            </Grid>
          </Grid>
        </button>
      )}
      <button className={styles.menu_item} onClick={handleCenterClick}>
        <Grid container>
          <Grid item xs={2} display={"flex"} alignItems={"center"}>
            {isAdminPage ? (
              <FontAwesomeIcon icon={faLanguage} size="1x" />
            ) : (
              <FontAwesomeIcon icon={faGear} size="1x" />
            )}
          </Grid>
          <Grid item xs={10}>
            <Typography>
              {isAdminPage ? t("word_language") : t("word_clinic_settings")}
            </Typography>
          </Grid>
        </Grid>
      </button>
      {!isAdminPage && (
        <button className={styles.menu_item} onClick={handleClick}>
          <Grid container>
            <Grid item xs={2} display={"flex"} alignItems={"center"}>
              <FontAwesomeIcon icon={faCircleQuestion} size="1x" />
            </Grid>
            <Grid item xs={10}>
              <Typography>{t("word_help")}</Typography>
            </Grid>
          </Grid>
        </button>
      )}
      <button className={styles.menu_item} onClick={handleRightClick}>
        <Grid container>
          <Grid item xs={2} display={"flex"} alignItems={"center"}>
            <FontAwesomeIcon icon={faSignOut} size="1x" />
          </Grid>
          <Grid item xs={10}>
            <Typography>{t("sign_out")}</Typography>
          </Grid>
        </Grid>
      </button>
      {/* </div> */}
    </Popover>
  );
};

export default UserMenu;
