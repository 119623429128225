// Components
import VisualFieldReport from "../VisualFieldReport";
import EstermanReport from "../EstermanReport";
import ColorVisionReport from "../ColorVisionReport";
import ContrastSensitivityReport from "../ContrastSensitivityReport";
import SingleThresholdReport from "../SingleThresholdReport";
import Breadcrumb from "../../../components/UI/ReportBreadcrumb";
import Loader from "../../../components/UI/Loader";
import PageIntro from "../../../components/UI/PageIntro";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";

// Utilities
import { getExamType } from "../../../utils/examReportsHelper";
import { ValidatePDFContext } from "../../../contexts/ValidatePDFContext";
import PrintButton from "../../../components/UI/PrintButton";
import { ErrorMessageComponent } from "../../../components/UI/ErrorMessage";
import { useContext, useMemo } from "react";
import { ReportDataContext } from "../../../contexts/ReportDataContext";
import { CommentAPInfoContext } from "../../../contexts/CommentContext";
import {
  AlgorithmContext,
  ExamTypeContext,
} from "../../../contexts/ExamPropertyContext";
import VisualAcuityReport from "../VisualAcuityReport";
import { AllFileTypes } from "../../../services/Exam";
import {
  Grid,
  IconButton,
  ThemeProvider,
  Tooltip,
  createTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ExamTransferContext } from "../../../contexts/ExamTransferContext";

const ReportPanel = ({
  downloadPdf,
  shouldDisplayReport,
  fileInfo,
  reportInfoError,
  shouldDisplayDicomButton,
  shouldDisplayPngButton,
}) => {
  const bpTheme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1300,
        xl: 1700,
      },
    },
  });
  const { t } = useTranslation();
  const { TYPE_VISUAL_ACUITY } = useContext(ExamTypeContext);
  const { landoltC, snellen } = useContext(AlgorithmContext);
  const { isLoading, patientName, exam } = useContext(ReportDataContext);
  const { patientId } = useContext(CommentAPInfoContext);
  const { handleExamTransferClick } = useContext(ExamTransferContext);
  const examAlgorithm = getExamType(exam);

  const ReportComponent = useMemo(() => {
    switch (examAlgorithm) {
      case "Esterman":
        return <EstermanReport />;
      case "D-15":
        return <ColorVisionReport />;
      case "Pelli Robson":
      case "Tumbling E":
        return <ContrastSensitivityReport />;
      case TYPE_VISUAL_ACUITY:
      case landoltC:
      case snellen:
        return <VisualAcuityReport />;
      case "Single Threshold":
        return <SingleThresholdReport />;
      default:
        return <VisualFieldReport />;
    }
  }, [TYPE_VISUAL_ACUITY, examAlgorithm, landoltC, snellen]);

  return (
    <>
      <ValidatePDFContext.Provider value={{ forBackendPdf: false }}>
        {isLoading ? (
          <Loader />
        ) : (
          <ThemeProvider theme={bpTheme}>
            <PageIntro
              pageTitle="Report"
              showBreadcrumb
              BreadcrumbComponent={
                <Breadcrumb
                  isLoading={isLoading}
                  patientId={patientId}
                  patientName={patientName}
                />
              }
              showAddButton={false}
              showDeleteButton={false}
              showFilterInput={false}
              desktopMarginTop={3}
              desktopMarginBottom={3}
            />
            {shouldDisplayReport && (
              <>
                <Grid>
                  <Tooltip title={t("tooltip_transfer_exam")}>
                    <span>
                      <a
                        href={`/patients/${patientId}/exam/${exam.id}`}
                        style={{ textDecoration: "none" }}
                        onClick={(e) => {
                          e.preventDefault();
                          handleExamTransferClick(exam, patientId);
                        }}
                      >
                        <IconButton
                          size="medium"
                          sx={{
                            color: "black",
                            "&.Mui-disabled": {
                              color: "white",
                              opacity: "0.5",
                            },
                          }}
                        >
                          <SwapHorizIcon fontSize="large" />
                        </IconButton>
                      </a>
                    </span>
                  </Tooltip>
                </Grid>
                <Grid container display={"flex"}>
                  <Grid item>
                    <PrintButton
                      isLoading={
                        isLoading ||
                        (fileInfo?.pdf && fileInfo?.pdf.hasBlobStorageError)
                      }
                      handleDownloadPdf={downloadPdf}
                      fileInfo={fileInfo}
                      reportInfoError={reportInfoError}
                    />
                  </Grid>
                  {shouldDisplayDicomButton && (
                    <Grid item ml={{ xs: 0, sm: 1, md: 2 }}>
                      <PrintButton
                        isLoading={
                          isLoading ||
                          (fileInfo?.dicom &&
                            fileInfo?.dicom?.hasBlobStorageError)
                        }
                        handleDownloadPdf={downloadPdf}
                        fileInfo={fileInfo}
                        reportInfoError={reportInfoError}
                        fileType={"dicom"}
                      />
                    </Grid>
                  )}
                  {shouldDisplayPngButton && (
                    <Grid item ml={{ xs: 0, sm: 1, md: 2 }}>
                      <PrintButton
                        isLoading={
                          isLoading ||
                          (fileInfo?.png && fileInfo?.png?.hasBlobStorageError)
                        }
                        handleDownloadPdf={downloadPdf}
                        fileInfo={fileInfo}
                        reportInfoError={reportInfoError}
                        fileType={AllFileTypes.png}
                      />
                    </Grid>
                  )}
                </Grid>
              </>
            )}
            {!shouldDisplayReport ? <ErrorMessageComponent /> : ReportComponent}
          </ThemeProvider>
        )}
      </ValidatePDFContext.Provider>
    </>
  );
};

export default ReportPanel;
