import algoliasearch from "algoliasearch";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  connectStateResults,
  Hits,
  InstantSearch,
  SearchBox,
} from "react-instantsearch-dom";
import { useNavigate } from "react-router-dom";
import styles from "./index.module.css";

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_API_KEY
);

const AlgoliaSearch = () => {
  const navigate = useNavigate();

  const [isInputFocused, setIsInputFocused] = useState(false);
  const searchContainerRef = useRef(null);

  const Results = connectStateResults(
    ({ searchState, searchResults, children }) => {
      const hasResults = searchResults && searchResults.nbHits !== 0;

      return (
        <div>
          {hasResults ? (
            children
          ) : (
            <div className={styles.noResultsMessage}>
              No results found for "{searchState.query}"
            </div>
          )}
        </div>
      );
    }
  );

  const handleClick = useCallback(
    (hit) => {
      setIsInputFocused(false);
      const subPath =
        typeof hit?.path === "string"
          ? hit.path.trim().split("/").filter(Boolean).pop()
          : "";
      if (window.location.pathname.includes(hit?.path)) {
        if (hit?.type === "main-link") {
          window.scrollTo({ top: 0, behavior: "smooth" });
        } else {
          const element = document.getElementById(subPath);
          if (element) {
            element.scrollIntoView({ behavior: "smooth" });
          }
        }
      } else {
        navigate(hit.path);
      }
    },
    [navigate]
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(event.target)
      ) {
        setIsInputFocused(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const Hit = ({ hit }) => (
    <div
      className={styles.hitItem}
      onClick={() => {
        handleClick(hit);
      }}
      style={{ cursor: "pointer" }}
    >
      <h4>{hit.title}</h4>
      <p>{hit.content}</p>
    </div>
  );

  return (
    <div ref={searchContainerRef} className={styles.searchContainer}>
      <InstantSearch searchClient={searchClient} indexName="algolia">
        <SearchBox
          onFocus={() => setIsInputFocused(true)}
          classNames={{
            form: styles.aisSearchBoxForm,
            input: styles.aisSearchBoxInput,
            submit: styles.aisSearchBoxSubmit,
            reset: styles.aisSearchBoxReset,
          }}
          translations={{
            placeholder: "Search the docs...",
          }}
        />
        <div
          className={`${styles.searchResultsContainer} ${isInputFocused ? styles.visible : styles.hidden}`}
        >
          <Results>
            <Hits hitComponent={Hit} />
          </Results>
        </div>
      </InstantSearch>
    </div>
  );
};

export default AlgoliaSearch;
