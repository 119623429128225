// Components
import Circle from "../Circle";
import ColoredCircle from "../ColoredCircle";
import Square from "../Square";
import SingleThresholdRing from "../SingleThresholdRing";
import Loader from "../Loader";
import ChartCross from "../ChartCross";

// Styles
import styles from "./index.module.css";

// Utilities
import {
  calculateXPaddingSingleThresholdGraph,
  calculateYPaddingSingleThresholdGraph,
} from "../../../utils/examReportsHelper";
import { useContext, useMemo, useRef } from "react";
import { ValidatePDFContext } from "../../../contexts/ValidatePDFContext";

const SingleThresholdGraph = ({
  pressedPoints,
  eye,
  gridType,
  containerSize = 624,
  tickNum = 6,
  borderWidth = 2,
}) => {
  const { forBackendPdf } = useContext(ValidatePDFContext);
  const containerRef = useRef(null);
  const size = useMemo(
    () =>
      containerSize
        ? forBackendPdf
          ? containerSize * 0.6
          : containerSize * 0.7
        : 624,
    [containerSize, forBackendPdf]
  );
  const tickSize = useMemo(() => size / 104, [size]);
  const scaleFactor = useMemo(() => size / 624, [size]);
  // Show loader while data is not yet processed
  if (pressedPoints.length === 0) {
    return <Loader containerHeight="624px" />;
  }

  const SingleThresholdRings = () => {
    const ringBaseDimension = size / tickNum;
    const baseMargin = size / 2;
    return (
      <>
        {Array.from({ length: tickNum }).map((_, i) => {
          const factor = tickNum - i;
          const computedMargin = baseMargin - (baseMargin / tickNum) * factor;
          const dimension = ringBaseDimension * factor;

          return (
            <div
              key={i}
              style={{
                position: "absolute",
                marginTop: computedMargin,
                marginLeft: computedMargin,
              }}
            >
              <SingleThresholdRing height={dimension} width={dimension} />
            </div>
          );
        })}
      </>
    );
  };

  return (
    <main
      ref={containerRef}
      className={styles.container}
      style={{
        "--cross-esterman-height": `${size}px`,
        "--cross-esterman-width": `${size}px`,
        "--cross-esterman-border-width": `${borderWidth}px`,
        "--cross-esterman-tick-size": `${tickSize}px`,
        "--half-height": `calc((${size}px - ${borderWidth}px) / 2)`,
        "--half-width": `calc((${size}px - ${borderWidth}px) / 2)`,
      }}
    >
      {SingleThresholdRings()}
      <ChartCross
        height={size}
        width={size}
        tickNum={tickNum}
        gridType={"FULL_120"}
      />
      {pressedPoints
        // .filter((spot) => spot.seen === false && spot.completed)
        .filter((spot) => spot.seen === false && spot.completed)
        .map((spot, i) => {
          return (
            <div
              key={i}
              style={{
                position: "absolute",
                paddingLeft: calculateXPaddingSingleThresholdGraph(
                  spot.x,
                  scaleFactor
                ),
                paddingTop: calculateYPaddingSingleThresholdGraph(
                  spot.y,
                  scaleFactor
                ),
              }}
            >
              <Square size={scaleFactor * 8} />
            </div>
          );
        })}
      {/* mapping the not completed visual spots. Display an orange circle by default, but display a green circle on the "current visual spot" */}
      {pressedPoints
        .filter((spot) => !spot.completed && spot.decibelValue) // considering the non completed ones, with a valid decibelValue/intensity
        .map((spot, i) => {
          return (
            <div
              key={i}
              style={{
                position: "absolute",
                paddingLeft: calculateXPaddingSingleThresholdGraph(
                  spot.x,
                  scaleFactor
                ),
                paddingTop: calculateYPaddingSingleThresholdGraph(
                  spot.y,
                  scaleFactor
                ),
              }}
            >
              <ColoredCircle isOrange={!spot.current} size={scaleFactor * 8} />
            </div>
          );
        })}
      {/* mapping the completed visual spots (live update)*/}
      {/* It's used for report as a `seen` */}
      {pressedPoints
        .filter((spot) => spot.seen)
        .map((spot, i) => {
          return (
            <div
              key={i}
              style={{
                position: "absolute",
                paddingLeft: calculateXPaddingSingleThresholdGraph(
                  spot.x,
                  scaleFactor
                ),
                paddingTop: calculateYPaddingSingleThresholdGraph(
                  spot.y,
                  scaleFactor
                ),
              }}
            >
              <Circle size={scaleFactor * 10} />
            </div>
          );
        })}
    </main>
  );
};

export default SingleThresholdGraph;
